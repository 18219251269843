import { Link } from "react-router-dom"

const Wholesale = () => {
    return (
        <div className="container">
            <h1 className=" text-center mb-5">Why? Award Winning Thirst Trap Wine-Based</h1> 

            <div className="row mb-5">
                <img src={require("../assets/imgs/home/header4.jpg")} alt=""  className="col-lg-3"/>
                <div className="col-lg-9 d-flex flex-column justify-content-center">
                    <h2>Wine-Based Cocktails That Drive Revenue Without Cannibalizing Sales </h2>
                    <p>Most Thirst Trap Cocktails are wine-based, ensuring they complement rather than compete with your existing beer and wine offerings. By introducing a new category, we provide an opportunity to drive meaningful revenue for your business.</p>
                </div>
            </div>

            <div className="row mb-5">
                <img src={require("../assets/imgs/home/header4.jpg")} alt=""  className="order-lg-2 img-thumb col-lg-3"/>
                <div className="col-lg-9 d-flex flex-column justify-content-center order-lg-1">
                    <h2>Proven Results: Boost Your Revenue </h2>
                    <p>Our clients report significant growth after launching Thirst Trap Cocktails. Before partnering with us, many were meeting the industrystandard benchmark of 3-5% of total revenue from beverages. With Thirst Trap Cocktails, these numbers have grown to an impressive15-20% of total revenue in their locations.</p>
                </div>
            </div> 

            <div className="row mb-5">
                <img src={require("../assets/imgs/home/header4.jpg")} alt=""  className="img-thumb col-lg-3"/>
                <div className="col-lg-9 d-flex flex-column justify-content-center">
                    <h2>Shelf-Stable for Convenience </h2>
                    <p>Our products are shelf-stable, lasting up to a year without refrigeration. This ensures minimal waste and maximum efficiency for your operations.</p>
                </div>
            </div>

            <div className="row mb-5">
                <img src={require("../assets/imgs/home/header4.jpg")} alt=""  className="order-lg-2 img-thumb col-lg-3"/>
                <div className="order-lg-1 col-lg-9 d-flex flex-column justify-content-center">
                    <h2>Flexible Options That are Easy on Service: Cans or Kegs or Post-Mix </h2>
                    <p>As a wine product, we offer flexibility in how you serve Thirst Trap Cocktails. Choose from cans or kegs based on your preferences. If you'reinterested in having our cocktails on tap, we also work with equipment leasing partners to make it happen. Want our flavors without alcohol? No problem, we can retrofit them to work on your soda guns or have them come out of your draft lines as a post-mix..</p>
                </div>
            </div> 

            <div className="text-center">
                <h2>Let's Talk!</h2>
                <p>Ready to transform your beverage program? Contact us today for a demo at sales@quickservecocktails.com.</p>
            </div>
        </div>

    )
}

export default Wholesale